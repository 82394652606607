
	import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';

	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import AuthenticationModalMixin from '@pixcap/ui-core/mixins/AuthenticationModalMixin';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import IconCrownNew from '@pixcap/ui-library/components/Icons/IconCrownNew.vue';
	import {
		Getters as UserGetters,
		GetterTypes as UserGetterTypes,
		NAMESPACE as USER_NAMESPACE,
		SUBSCRIPTION_UPGRADE_CASE,
		UPGRADE_PLAN_TRIGGER_TYPE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import PixcapDivThumbnail from '@pixcap/ui-library/components/PixcapDivThumbnail.vue';
	import { actionsWrapper as ProjectActions } from '@/store/project/wrapper';
	import {
		IAssetsLibraryState,
		LIBRARY_TAB,
		NAMESPACE as LIBRARY_NAMESPACE,
		PixcapLibraryFile,
		SEARCH_ROUTES,
	} from '@/models/store/pixcapassets.interface';
	import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { actionsWrapper as LibraryActions, mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import LibraryItemListLoader from '@/components/pixcap-library/shared/LibraryItemListLoader.vue';
	import PixcapLibraryItem from '@/components/pixcap-library/shared/PixcapLibraryItem.vue';
	import { setupBaseMetaTags } from '@/utils/seo';

	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import IconCaretRight from '@pixcap/ui-library/components/Icons/IconCaretRight.vue';
	import ExploreItemTypeBanner from '@/components/banners/ExploreItemTypeBanner.vue';
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';

	@Component({
		name: 'LibraryItemDetails',
		components: {
			IconCrown,
			ExploreItemTypeBanner,
			IconCaretRight,
			HeadingText,
			BodyText,
			CaptionText,
			Button,
			IconCrownNew,
			PixcapLibraryItem,
			IconLargeArrowRight,
			LibraryItemListLoader,
			PixcapDivThumbnail,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				activeExplorerTab: (state: IAssetsLibraryState) => state.activeExplorerTab,
			}),
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
			}),
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
			}),
		},
		// Use fetch here due to extending the authModal which use the component without nuxt hooks registred
		async fetch() {
			await this.fetchLibraryItemDetails();
			await this.fetchingRelatedPackItems();
		},
		head() {
			const headMeta: any = {
				meta: [],
				script: [],
			};
			const metaTagsPaylod: any = {
				url: `${process.env.ROOT_URL}${this.$route.fullPath}`,
			};
			const libraryItemDetails = this.libraryItemDetails;
			if (libraryItemDetails) {
				metaTagsPaylod.image = this.thumbnailUrl;
				const keyWord = libraryItemDetails.itemName;
				metaTagsPaylod.title = this.$t('seo_meta.title_item_detail', { keyWord });
				metaTagsPaylod.description = this.isCharacterItem
					? this.$t('seo_meta.description_item_character_detail', { keyWord })
					: this.$t('seo_meta.description_item_icon_detail', { keyWord });
				metaTagsPaylod.script = {
					json: {
						'@context': 'http://schema.org',
						'@type': 'ImageObject',
						name: metaTagsPaylod.title,
						contentUrl: metaTagsPaylod.image,
						description: metaTagsPaylod.description,
					},
					type: 'application/ld+json',
					'data-test': 'image-object',
				};
			}
			setupBaseMetaTags(metaTagsPaylod, headMeta, this.$route);
			return headMeta;
		},
	})
	export default class LibraryItemDetails extends Mixins(AuthenticationModalMixin) {
		@Prop() itemSlug: string;

		personalData: UserState['personalData'];

		activeExplorerTab: IAssetsLibraryState['activeExplorerTab'];
		libraryItemDetails: IAssetsLibraryState['libraryItemDetails'] = null;
		relatedModels: IAssetsLibraryState['relatedModels'] = null;
		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;

		isFetchingItemDetails = false;
		isFetchingRelatedModels = false;
		isShowAllTags = false;
		isFetchingRelatedPackItems = false;

		relatedPackItems: PixcapLibraryFile[] = [];
		relatedPackSlug: string = '';
		relatedPackId: string;
		LIBRARY_TAB = LIBRARY_TAB;
		SEARCH_ROUTES = SEARCH_ROUTES;

		get isOwned() {
			if (this.isProOrAbove) return true;
			return this.personalData?.user?.purchaseHistory?.includes(this.libraryItemDetails.itemId);
		}

		get itemName() {
			return this.libraryItemDetails.itemName;
		}

		get thumbnailUrl() {
			if (!this.libraryItemDetails) return null;
			return this.libraryItemDetails.thumbnailUrl?.url;
		}

		get uniqueSlugTitle() {
			return this.libraryItemDetails?.slug.split('-').slice(-1)[0];
		}

		get detailsDescription() {
			return this.libraryItemDetails.description ?? this.isCharacterItem
				? this.$t('page.item_details.features.character_description', { item_name: this.libraryItemDetails.itemName })
				: this.$t('page.item_details.features.item_description', { item_name: this.libraryItemDetails.itemName });
		}

		get thumbnailAltTag() {
			if (!this.libraryItemDetails) return '';
			return this.itemName;
		}

		get isCharacterItem() {
			return this.libraryItemDetails && this.libraryItemDetails.resourceType?.toLowerCase() === LIBRARY_RESOURCE_TYPE.CHARACTERS;
		}

		get relateItemQuery() {
			return this.libraryItemDetails?.relatedQuery;
		}

		get isClientRender() {
			return process.client;
		}

		@Watch('itemSlug')
		onItemSlugChange() {
			this.fetchLibraryItemDetails();
			this.fetchingRelatedPackItems();
		}

		@Watch('libraryItemDetails.itemId')
		onRelatedQueryChange(next, prev) {
			if (next && next != prev) {
				this.fetchingRelatedPackItems();
			}
		}

		handleClickTag() {
			LibraryMutations.setSelectedLibraryItemSlug(this.$store, null);
		}

		handleShowAllRelatedPack() {
			LibraryMutations.setSelectedLibraryItemSlug(this.$store, null);
			this.$router.push({
				name: 'LibraryPackDetailsPage',
				params: {
					packSlug: this.relatedPackSlug,
				},
			});
		}

		handleShowRelatedResource() {
			LibraryMutations.setSelectedLibraryItemSlug(this.$store, null);
			this.$router.push({
				name: 'LibraryExploreSearchList',
				params: { tab: SEARCH_ROUTES.ITEM_ELEMENTS, searchQuery: this.libraryItemDetails.tags[0].tagName },
			});
		}

		async fetchLibraryItemDetails() {
			this.isFetchingItemDetails = true;
			this.relatedModels = [];
			this.libraryItemDetails = await LibraryActions.fetchLibraryFileDetails(this.$store, this.itemSlug);
			this.isFetchingItemDetails = false;
			this.countView();
			if (this.relateItemQuery) {
				this.isFetchingRelatedModels = true;
				this.relatedModels = await LibraryActions.fetchRelatedModels(
					this.$store,
					this.relateItemQuery,
					[this.libraryItemDetails.itemId],
					undefined,
					'transparent',
					400,
					undefined,
					'webp',
					this.isCharacterItem ? [LIBRARY_RESOURCE_TYPE.CHARACTERS] : [LIBRARY_RESOURCE_TYPE.MODELS]
				);
				this.isFetchingRelatedModels = false;
			}
			if (!this.libraryItemDetails)
				this.$nuxt.error({
					message: 'Page not found',
					statusCode: 404,
				});
		}

		async fetchingRelatedPackItems() {
			this.isFetchingRelatedPackItems = true;
			this.resetRelatedData();
			const pack = await LibraryActions.fetchPackItems(this.$store, this.libraryItemDetails.itemId);
			this.relatedPackItems = pack.packItems;
			this.relatedPackSlug = pack.slug;
			this.relatedPackId = pack.packId;

			this.isFetchingRelatedPackItems = false;
		}

		resetRelatedData() {
			this.relatedPackItems = [];
			this.relatedPackSlug = '';
			this.relatedPackId = '';
		}

		_checkUserPermission(isMustPro = false, triggerReason?: string) {
			if (isMustPro) {
				if (!this.isOwned) {
					UserMutations.setUpgradeModalState(this.$store, {
						triggerCase: SUBSCRIPTION_UPGRADE_CASE.USE_PRO_ITEM,
					});
					return true;
				}
			}
			return false;
		}

		handleEditModal() {
			if (this.libraryItemDetails.isPro && !this.isAuthenticated) {
				this.showAuthenticationModal();
				return;
			}
			if (this.isAuthenticated && this._checkUserPermission(this.libraryItemDetails.isPro, 'Item Details Edit')) return;
			const query = {
				slug: this.libraryItemDetails.slug,
			};
			ProjectActions.createDesignProject(this.$store, this.$router, query, 'Remix in Mix and Match', {
				'Item Name': this.libraryItemDetails.itemName,
				'Item Slug': this.libraryItemDetails.slug,
				'Item Type': this.libraryItemDetails.itemType,
			});
		}

		countView() {
			if (this.libraryItemDetails && process.client) LibraryActions.viewLibraryItem(this.$store, this.libraryItemDetails.itemId);
		}

		mounted() {
			this.countView();
		}

		beforeDestroy() {
			this.$pixcap.$services.authService.setOnAuthenticated(undefined);
			LibraryMutations.updateLibraryItemDetails(this.$store, null);
		}
	}
